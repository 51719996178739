<script lang="ts" setup>
import { ref, computed, watch, getCurrentInstance } from "vue";
import { postLocationNewRegisterDetails } from "@/services/api/location.api";
import { getRegisterTypes, getRegisterEnergyTypes, getRegisterUnitTypes, getRegisterSources } from "@/services/api/masterdata.api";
import { ValidationObserver, ValidationProvider, setInteractionMode } from "vee-validate";
import useDetailEdit from "@/composables/detailEdit.js";
import { navigateToLocation } from "@/composables/general.js";
import router from "@/router/index";
import DateInput from "@/components/general/DateInput.vue";

const {
  selectedData,
  checkDateValid,
} = useDetailEdit();

setInteractionMode("eager");

const props = defineProps({
  locationReference: String,
});

const typeList = ref([]);
const energyTypeList = ref([]);
const unitTypeList = ref([]);
const registerSourcesList = ref([]);
const isSaving = ref(false);

const masterDataLoaded = computed(() => true);
const allDataLoaded = computed(() => (masterDataLoaded.value) );
watch(
  () => props.locationReference,
  () => {
    getMasterDataRegisterTypes();
    getMasterDataEnergyTypes();
    getMasterDataUnitTypes();
    getMasterDataRegisterSources();
  },
  { immediate: true }
);

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function getMasterDataRegisterTypes() {
  const types = await getRegisterTypes();
  typeList.value = types.data.registerTypes.filter((x) => x.source == null).map((x) => ({ key: x.id, description: x.descr }));
}

async function getMasterDataEnergyTypes() {
  const types = await getRegisterEnergyTypes();
  energyTypeList.value = types.data.energyTypes.map((x) => ({ key: x.id, description: x.descr }));
}

async function getMasterDataUnitTypes() {
  const types = await getRegisterUnitTypes();
  unitTypeList.value = types.data.unitTypes.map((x) => ({ key: x.id, description: `${x.name} (${x.descr})` }));
}

async function getMasterDataRegisterSources() {
  const sources = await getRegisterSources();
  console.log("Register, getRegisterSources ", sources);

  registerSourcesList.value = sources.data.keyDescList.map((x) => ({ key: x.key, description: x.description }));
}

const obs = ref(null);

async function saveNewDetail() {
  isSaving.value = true;
  console.log("LocationNewRegisterDetails, saveNewDetail", props.locationReference);
  const valid = await obs.value.validate();
  if (valid) {
      let data = {};
      data = Object.assign({}, selectedData.value);
      data.locationReference = props.locationReference;

      console.log("LocationNewRegisterDetails, saveNewDetail, data: ", data);
      const response = await postLocationNewRegisterDetails(props.locationReference, data);

      if (!response.error) {
        proxy.$toaster.success("Meter en gegevens van de koppeling zijn successvol toegevoegd.");
          router.push({ name: "EU-Register", params: { registerId: response.data.registerId } });
      } else {
        proxy.$toaster.error(response.error);
      }
  }
  isSaving.value = false;
}

async function onCancelAdd() {
    proxy.$toaster.warning(`Meter toevoegen en koppelen geannuleerd.`);
    navigateToLocation(props.locationReference.toString());
}

</script>

<template>
  <v-container fluid pa-0>
    <header class="app-welcome-message d-flex">
      <h2>Toevoegen nieuwe meter en koppelen met locatie</h2>
      <h2 :class="{ loadingDots: !allDataLoaded }"></h2>
    </header>

    <v-row>
      <v-col cols="1">
        <v-btn dark class="primary" :to="{ name: 'LocationRegisterDetailsAdd', params: { locationReference: locationReference.toString(), addModus: true } }">
          Of kies een bestaande meter
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <ValidationObserver ref="obs">
          <v-form>
            <masonry :cols="{ default: 2, 1264: 1 }" :gutter="12">
              <v-container class="boxed" fluid :class="{ noBorders: false }">
                <header>Geldigheid koppeling</header>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Geldig vanaf" rules="date_between|required" v-slot="{ errors }" :mode="checkDateValid">
                      <date-input
                        v-model="selectedData.valFrom"
                        persistent-placeholder
                        label="Geldig vanaf"
                        :errors="errors"
                        :class="{ noBorders: false }"
                        :outlined="true"
                        rules="required"
                      ></date-input>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Geldig tot" :rules="`date_between|date_after:${selectedData.valFrom}`" v-slot="{ errors }" :mode="checkDateValid">
                      <date-input
                        v-model="selectedData.valTill"
                        persistent-placeholder
                        label="Geldig tot"
                        :errors="errors"
                        :class="{ noBorders: false }"
                        :outlined="true"
                      ></date-input>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-container>

              <v-container class="boxed" :class="{ noBorders: false }">
                <header>Aanpassing</header>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Aanpassings omschrijving" rules="required" v-slot="{ errors }">
                      <v-text-field
                        hide-details="auto"
                        persistent-placeholder
                        label="Omschrijving"
                        v-model="selectedData.modDescr"
                        :error-messages="errors"
                        :class="{ noBorders: false }"
                        :outlined="true"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-container>
            </masonry>

            <masonry :cols="{ default: 2, 1264: 1 }" :gutter="12">
              <v-container class="boxed" fluid :class="{ noBorders: true }">
                <header>Details koppeling</header>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Gebruiksfactor" rules="required|decimal:4|between:0,1" v-slot="{ errors }">
                      <v-text-field
                        type="number"
                        hide-details="auto"
                        label="Gebruiksfactor"
                        v-model="selectedData.allocFact"
                        :error-messages="errors"
                        persistent-placeholder
                        :outlined="true"
                        :class="{ noBorders: false }"
                      >
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-container>
            </masonry>

            <masonry :cols="{ default: 3, 768: 2, 1264: 1 }" :gutter="12" :class="{ noBorders: false }">
              <v-container class="boxed">
                <header>Algemene gegevens meter</header>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Omschrijving" rules="required" v-slot="{ errors }">
                      <v-text-field
                        hide-details="auto"
                        label="Omschrijving"
                        persistent-placeholder
                        v-model="selectedData.description"
                        :error-messages="errors"
                        :outlined="true"
                        :class="{ noBorders: false }"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <ValidationProvider name="Type" rules="required" v-slot="{ errors }">
                      <v-select
                        hide-details="auto"
                        persistent-placeholder
                        label="Type"
                        :items="typeList"
                        item-text="description"
                        item-value="key"
                        v-model="selectedData.typeId"
                        :error-messages="errors"
                        :outlined="true"
                        :class="{ noBorders: false }"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="EnergyType" rules="required" v-slot="{ errors }">
                      <v-select
                        hide-details="auto"
                        persistent-placeholder
                        label="Energietype"
                        :items="energyTypeList"
                        item-text="description"
                        item-value="key"
                        v-model="selectedData.energyTypeId"
                        :error-messages="errors"
                        :outlined="true"
                        :class="{ noBorders: false }"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="UnitType" rules="required" v-slot="{ errors }">
                      <v-select
                        hide-details="auto"
                        persistent-placeholder
                        label="Eenheid"
                        :items="unitTypeList"
                        item-text="description"
                        item-value="key"
                        v-model="selectedData.unitTypeId"
                        :error-messages="errors"
                        :outlined="true"
                        :class="{ noBorders: false }"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-container>

              <v-container class="boxed">
                <header>Bron</header>
                <v-row>
                  <v-col>
                    <v-switch
                      hide-details="auto"
                      label="Indicatief"
                      persistent-placeholder
                      v-model="selectedData.isIndicative"
                      :outlined="true"
                      :class="{ noBorders: false }"
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Bron" rules="required" v-slot="{ errors }">
                      <v-select
                        hide-details="auto"
                        label="Bron"
                        :items="registerSourcesList"
                        item-text="description"
                        item-value="key"
                        persistent-placeholder
                        v-model="selectedData.source"
                        :error-messages="errors"
                        :outlined="true"
                        :class="{ noBorders: false }"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Bronreferentie" rules="required" v-slot="{ errors }">
                      <v-text-field
                        hide-details="auto"
                        label="Bronreferentie"
                        persistent-placeholder
                        v-model="selectedData.sourceReference"
                        :error-messages="errors"
                        :outlined="true"
                        :class="{ noBorders: false }"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-container>
              <v-container class="boxed">
                <header>Notities</header>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Notities" rules="max:1000" v-slot="{ errors }">
                      <v-text-field
                        hide-details="auto"
                        persistent-placeholder
                        v-model="selectedData.note"
                        :counter="1000"
                        :error-messages="errors"
                        :outlined="true"
                        :class="{ noBorders: false }"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-container>
            </masonry>

          </v-form>
        </ValidationObserver>

        <v-container fluid>
          <v-row>
          <v-col cols="auto" class="form-group">
            <v-btn class="primary" v-on:click="saveNewDetail()" :disabled="isSaving">
              <v-icon dark left>mdi-checkbox-marked-circle</v-icon>
              Opslaan, en door naar verbruiksgegevens
            </v-btn>
          </v-col>
          <v-col cols="auto" class="form-group">
            <v-btn v-on:click="onCancelAdd()">
              <v-icon dark left>mdi-cancel</v-icon>
              Annuleren
            </v-btn>
          </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-container>

      </v-col>
    </v-row>
  </v-container>
</template>
